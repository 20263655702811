export default {
  key: 'ccvba',
  canonicalUrl: 'ccvba.fr',
  datalayerId: 'GTM-N7FRS7X',
  customerId: 684,
  icon: 'ccvba.ico',
  headerLogo: 'ccvba.png',
  footerLogo: 'ccvba_monochrome.png',
  clientUrl: "https://ccvba.fr/client",
  proUrl: "https://ccvba.fr/pro",
  sanity: {
    base: {
      projectId: 'x0zhji74',
      dataset: 'production',
      apiVersion: "2022-12-01",
      useCdn: true,
    },
    preview: {
      useCdn: false,
      withCredentials: true,
      token: 'skMz2Ppjkm28ifhxSbIVwdabTlEYIVf9hhCEFYprKU0RXFwwCYplW0lUyFZycL2vflQfkMRtwyHwKMtKpqyI4Q1H0i5xogg4lOsmkS5VDPlB9EnNXjDsUm0p4gYmUiS6mfiddx8C2k96I2GhYz8ZLVOrzWVT2M7dhhQqk0XBmhcT90Eb3tVE',
    }
  },
  dev: {
    clientUrl: "https://dev.tourismebyca.fr/",
    proUrl: "https://dev.tourismebyca.fr/",
  },
}