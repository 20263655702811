import React from "react";
import Logo from "../Logo";
import { imageUrlFor } from "../../tools/sanity";
import { RequirementsProps } from "../../tools/context";
import { LinkProps } from "../index";

export interface ImageProps {
  media: any;
  link?: string;
}
export interface SocialProps {
  image: any;
  link?: string;
  alt?: string;
}
export interface FooterProps {
  footer?: {
    navigation?: LinkProps[];
    bottomLinks?: LinkProps[];
    rightLogo?: ImageProps;
    socialList?: SocialProps[];
  };
  requirements: RequirementsProps;
}

export const Footer = ({ footer, requirements }: FooterProps) => {
  const links = footer && footer.navigation ? footer.navigation : [];
  const bottomLinks = footer && footer.bottomLinks ? footer.bottomLinks : [];
  const rightLogo = footer && footer.rightLogo ? footer.rightLogo : null;
  const socialList = footer && footer.socialList ? footer.socialList : [];

  const RightLogoComp = rightLogo?.link ? "a" : "div";
  const RightLogoProps = rightLogo?.link
    ? { href: rightLogo.link, target: "_blank", rel: "noopener" }
    : {};

  return (
    <div className={"Footer"}>
      <div className="footer-content">
        <div className="footer-logo">
          <Logo location={"footer"} requirements={requirements} />
          <div className="social-images-row">
            {socialList.map((social, index) => (
              <a
                key={index}
                className="social-image"
                href={social.link ? social.link : "#"}
                target="_blank"
                rel="noopener"
              >
                <img
                  src={imageUrlFor(social.image, requirements).url()}
                  alt={social.alt ? social.alt : "Social image"}
                />
              </a>
            ))}
          </div>
        </div>
        <div className="links">
          {links.map((link, index) => (
            <a
              className={"link"}
              key={index}
              href={link.link}
              target={link.external ? "_blank" : null}
            >
              {link.text}
            </a>
          ))}
        </div>
        <div className="footer-logo">
          {rightLogo && rightLogo.media && (
            <RightLogoComp className="block" key="test" {...RightLogoProps}>
              <img src={imageUrlFor(rightLogo.media, requirements).url()} />
            </RightLogoComp>
          )}
        </div>
      </div>
      <div className="footer-bottom">
        {bottomLinks.map((link, index) => (
          <React.Fragment key={index}>
            <a className={`link ${link.link ? "url" : ""}`} href={link.link}>
              {link.text}
            </a>
            <div className="dot" />
          </React.Fragment>
        ))}
        <a className={`link url`} href="javascript:openAxeptioCookies()">
          Cookies
        </a>
      </div>
    </div>
  );
};
