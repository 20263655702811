import React, { Fragment, useContext, useEffect, useState } from "react";
import {
  getCookies,
  getOrCreateCiblerId,
  getQueryString,
  replaceQueryString,
} from "../../tools/tools";
import { RequirementsProps } from "../../tools/context";
import Page404 from "../404";
import { getItinerary, getNearby, putItinerary } from "../../tools/ciblerAPI";
import { ChangeActivityDirection, TravelDays } from "./TravelDays";
import { useTranslation } from "../../tools/i18n";
import { OverLayParameter } from "../../App";
import { ResponsiveImage } from "../ResponsiveImage";
import { Picto } from "../Picto";
import { ChatAgent } from "../ChatAgent";
import moment from "moment";
import { useTracking } from "../../tools/analytics";
import { FeedbackPopin } from "./FeedbackPopin";
import { DirectionProvider } from "./DirectionContext";
import { TravelCustomMap } from "./TravelCustomMap";
import Restaurants from "./Restaurants";
import * as Configurations from "../../configurations";
import { Header } from "./Header";
import { ProgressionBar } from "./ProgressionBar";
import { Beta } from "./Beta";
import { Review } from "./Review";
import Footer from "./Footer";
import SitesExplorator, { SitesExploratorSite } from "./SitesExplorator";
import { makeAnAlgoliaSearch } from "../../tools/searchAPI";
import {
  TravelPlanerResultsContext,
  TravelPlanerResultsProvider,
} from "./TravelPlanerResultsContext";
import { TravelPlannerResultsConfigType } from "./types";
import { TravelPlannerConfigType } from "../TravelPlaner/types";
import { getSanityClient } from "../../tools/sanity";
import { TravelPlannerSanityProps } from "../TravelPlanerHub";
import { Months } from "../TravelPlaner/PeriodsOrDates";
import { useWindowSize } from "../../tools/hooks";

if (typeof window !== "undefined") {
  require("leaflet/dist/leaflet.css");
}

interface TripPoint {
  type: "site" | "activity";
  city: string;
  latitude: number;
  description: string;
  description_en: string;
  description_es: string;
  description_de: string;
  description_it: string;
  description_nl: string;
  timeEstimate: string;
  matching: string;
  urlimg: string;
  name: string;
  store_name: string;
  time: string;
  formatted_address?: string;
  modalities?: string;
  free?: string;
  possible_fee?: string;
  tca_categ: string;
  id: string | number;
  category: string;
  longitude: number;
  site?: string;
  tarif?: string;
  price_range?: string;
  start_date?: string;
  end_date?: string;
}

export interface TravelPlanerResultsProps {
  requirements: RequirementsProps;
  navigation: any;
  headerButtons: any;
  componentStates: {
    journey: any;
    restaurants: any;
    articles: any;
    lodgings: any;
    sites: any;
    travelPlanner: TravelPlannerSanityProps;
  };
  setOverlay: (p: OverLayParameter) => void;
  feedbackUrl?: string;
  agentId: string;
  agentKey: string;
  config: TravelPlannerResultsConfigType;
  hideAgentKeyCss: boolean;
  siteConfiguration: any;
}

const Renderer = (props: TravelPlanerResultsProps) => {
  const { requirements, agentKey, config, hideAgentKeyCss, componentStates } =
    props;
  const { restaurants, lodgings, travelPlanner } = componentStates;
  const { travelForceLogin } = requirements.config;
  const { width } = useWindowSize();
  const isMobile = width < 768;
  const [dayOfTheWeek, setDayOfTheWeek] = useState<number>(1);
  const [review, setReview] = useState<"up" | "down">();
  const [showReview, setShowReview] = useState<boolean>(false);
  const [showFeedback, setShowFeedback] = useState<boolean>(false);
  const [trip, setTrip] = useState({});
  const { t, i18n } = useTranslation();
  const { trackEvent } = useTracking();
  const {
    journey,
    day,
    setDay,
    showAllSites,
    setShowAllSites,
    showMobileMap,
    setShowMobileMap,
    formattedData,
    setFormattedData,
    selectedActivities,
    setSelectedActivities,
    sites,
    setSites,
  } = useContext(TravelPlanerResultsContext);
  const { travelData: results } = journey;
  const inputsOfTravel = journey.inputs.inputs;
  const lodging = results?.inputs?.lodging;
  const lodgingPoint = lodging
    ? lodging.address?.address
      ? lodging.address
      : lodging.hotel
    : null;



  useEffect(() => {
    // Use the agentKey to have different configurations for the same partner
    if (agentKey?.length) {
      // Add the configuration for the agent
      requirements.config =
        Configurations[`${requirements.partner}_${agentKey}`] ||
        requirements.config;

      // Add custom CSS
      if (!hideAgentKeyCss) {
        const head = document.getElementsByTagName("head")[0];
        const oldStyle = document.getElementById(
          `customCSS_${requirements.config.key}`
        );
        const partnerDefaultStyle = document.getElementById("customCSS");
        if (partnerDefaultStyle) {
          partnerDefaultStyle.remove();
        }
        if (!oldStyle) {
          const link = document.createElement("link");
          link.id = `customCSS_${requirements.config.key}}`;
          link.rel = "stylesheet";
          link.type = "text/css";
          link.href = `//${window.location.host}/custom/${requirements.config.key}.css`;
          link.media = "all";
          head.appendChild(link);
        }
      }

      if (!i18n.language.includes(agentKey)) {
        const newLanguage = `${i18n.language}_${agentKey}`;
        i18n
          .changeLanguage(newLanguage, (err, t) => {
            if (err) return console.error("something went wrong loading", err);
          })
          .catch((e) => console.error("Error changing language", e));
      }
    }
  }, [agentKey]);

  useEffect(() => {
    if (!trip || !day) return;
    Object.keys(trip).forEach((day) => {
      const keys = Object.keys(trip[day]);
      const initialSelectedActivities = {};

      keys.forEach((key) => {
        initialSelectedActivities[key] = 0;
      });

      setFormattedData((d) => ({
        ...d,
        [day]: keys.map((key) => ({ key, value: trip[day][key] })),
      }));
      setSelectedActivities((a) => ({
        ...a,
        [day]: initialSelectedActivities,
      }));
    });
  }, [day, trip]);

  useEffect(() => {
    const trip = journey?.travelData?.trip || {};
    setTrip(trip);
    if (!trip || day) return;
    const keys = Object.keys(trip);
    setDay(keys.length ? keys[0] : "");
  }, [journey]);

  useEffect(() => {
    const dayOfTheWeek = moment(results?.inputs?.StartDate).day();
    setDayOfTheWeek(dayOfTheWeek);
    setTrip(results?.trip || {});
  }, [results]);

  const handleSetDays = (d: string) => {
    const cookies = getCookies();
    if (cookies.loginToken || !travelForceLogin) {
      setDay(d);
      return;
    }
    props.setOverlay({ key: "loginPopup", context: { queries: { day: d } } });
  };

  const handleReview = async (value: any) => {
    if (review) return;

    trackEvent(`review${value}_click`);
    setReview(value);
    const { endpoint, config } = requirements;
    const id = requirements.parameters[0];
    const headers = new Headers();
    await fetch(
      `${endpoint}/api/poi/journey/${id}/feedback?positive=${
        value === "up"
      }&customer=${config.customerId}&ciblerId=${getOrCreateCiblerId()}&info`,
      {
        headers,
        method: "POST",
      }
    );
    if (value === "down") {
      setShowFeedback(true);
    }
  };

  useEffect(() => {
    const cookies = getCookies();
    let newDay = null;
    if (cookies.loginToken || !travelForceLogin) {
      const d = getQueryString("day");
      if (d) {
        newDay = d;
        replaceQueryString("day", null);
      }
      const review = getQueryString("review");
      if (review) {
        handleReview(review);
        replaceQueryString("review", null);
      }
    }
    if (getQueryString("auth")) {
      setShowReview(true);
    }

    if (results && results.trip && !newDay) {
      const keys = Object.keys(results.trip);
      newDay = keys.length ? keys[0] : "";
    }
    setDay(newDay);
  }, []);

  if (!results) return <Page404 {...props} />;
  const { inputs } = results || {};
  const showFullScreenRestaurants =
    config.fullScreen &&
    config.showRestaurants &&
    (restaurants?.length || 0) > 0;
  const showFullScreenLodgings =
    config.fullScreen &&
    config.showLodgings &&
    (lodgings?.length || 0) > 0 &&
    !lodgingPoint;

  const handleChangeActivity = (
    direction: ChangeActivityDirection,
    key: string
  ) => {
    const currentData = formattedData[day].find(
      (d: { key: string; value: any }) => d.key === key
    );
    const copy = { ...selectedActivities };

    if (direction === "prev")
      copy[day][key] =
        copy[day][key] === 0
          ? currentData.value.length - 1
          : copy[day][key] - 1;
    else
      copy[day][key] =
        copy[day][key] === currentData.value.length - 1
          ? 0
          : copy[day][key] + 1;

    setSelectedActivities(copy);
  };

  if (!results) return <Page404 {...props} />;

  const id = requirements.parameters[0];

  const getFormattedDates = (): string => {
    if (results.inputs?.startMonth)
      return Object.keys(Months)?.includes(results.inputs?.startMonth)
        ? t(`travelPlaner.PeriodsOrDates.months.${results.inputs?.startMonth}`)
        : results.inputs?.startMonth;
    // 29 Dec 23 &gt; 30 Dec 23
    if (!results.inputs?.StartDate && !results.inputs?.startDate) return "";
    const start = new Date(
      results.inputs?.StartDate || results.inputs?.startDate
    );
    const nbDays = results.inputs?.DayNumber || results.inputs?.tripLength || 1;
    if (nbDays === 1) return moment(start).format("D MMM YY");
    const end = new Date(
      results.inputs?.StartDate || results.inputs?.startDate
    );
    end.setDate(end.getDate() + nbDays - 1);
    return `${moment(start).format("D MMM YY")} > ${moment(end).format(
      "D MMM YY"
    )}`;
  };

  const handleMapAction = (action: string): void => {
    if (action === "sites") {
      setShowAllSites(true);
      setShowMobileMap(false);
    }
  };

  const handleDeleteTravelPoint = (index: number, dayIndex?: number): void => {
    const finalDayIndex =
      dayIndex || Object.keys(trip).findIndex((d) => d === day);
    setSites(
      sites.map((s) =>
        s.pointIndex === index && s.dayIndex === finalDayIndex
          ? { ...s, dayIndex: undefined, pointIndex: undefined }
          : s
      )
    );
    const newTrip = {};
    const days = Object.keys(trip);

    days.forEach((d, dIndex) => {
      const points = Object.keys(trip[d]);
      const newPoints = {};
      points.forEach((p, pointIndex) => {
        if (index !== pointIndex || finalDayIndex !== dIndex)
          newPoints[p] = trip[d][p];
      });
      newTrip[d] = newPoints;
    });

    setTrip(newTrip);
    putItinerary(id, { ...results, trip: newTrip }, requirements);
  };

  const convertSiteToTripPoint = (s: SitesExploratorSite): TripPoint => ({
    type: s.type || "site",
    id: s.id,
    latitude: s.lat,
    longitude: s.lng,
    name: s.store_name,
    description: s.post_content,
    description_en: s.post_content_en,
    description_es: s.post_content_es,
    description_de: s.post_content_de,
    description_it: s.post_content_it,
    description_nl: s.post_content_nl,
    formatted_address: s.type === "site" ? s.formatted_address : undefined,
    modalities: s.type === "site" ? s.modalities : undefined,
    free: s.type === "site" ? s.free : undefined,
    possible_fee: s.type === "site" ? s.possible_fee : undefined,
    tca_categ: s.type === "site" ? s.tca_categ : "",
    store_name: s.store_name,
    urlimg: s.urlimg,
    city: "",
    timeEstimate: "",
    category: "",
    matching: "",
    time: "",
    site: s.type === "activity" ? s.site : undefined,
    tarif: s.type === "activity" ? s.tarif : undefined,
    price_range: s.type === "activity" ? s.price_range : undefined,
    start_date: s.type === "activity" ? s.start_date : undefined,
    end_date: s.type === "activity" ? s.end_date : undefined,
  });

  const handleChangeTravelPoint = (
    dayIndex: number,
    point: SitesExploratorSite
  ): void => {
    if (dayIndex === -1 || dayIndex === point.dayIndex) return;
    const days = Object.keys(trip);
    const targetDay = days[dayIndex];
    const targetDayPoints = Object.keys(trip[targetDay]);
    const pointIndex = targetDayPoints.length;

    setSites(
      sites.map((s) =>
        s.id === point.id ? { ...point, dayIndex, pointIndex } : s
      )
    );

    const newTrip = {};
    const newTripPointKey = `added_${point.id}`;

    days.forEach((d, dIndex) => {
      if (dIndex === dayIndex) {
        // Day where we want to add the site
        newTrip[d] = {
          ...trip[d],
          [newTripPointKey]: [convertSiteToTripPoint(point)],
        };
      } else {
        // Days where we want to remove the site if present
        newTrip[d] = {};
        Object.keys(trip[d]).forEach((tp) => {
          if (tp !== newTripPointKey) {
            newTrip[d][tp] = trip[d][tp];
          }
        });
      }
    });

    setTrip(newTrip);
    putItinerary(id, { ...results, trip: newTrip }, requirements);
  };

  const interests = { ...inputsOfTravel.interests, ...inputsOfTravel.newInterests };
  const selectedInterests = Object.keys(interests).filter(
    (i) => interests[i].note === 3
  );  

  return (
    <div
      className={`travelPlanerResultsNewUI ${
        config.showHeader ? "withHeader" : ""
      } ${config.fullScreen ? "fullScreen" : ""}`}
    >
      {inputs.cityKey ? (
        <ResponsiveImage
          className="headerImage"
          requirements={requirements}
          src={`https://cdn.cibler.io/static/tca/v2/travelPlaner/${inputs.cityKey}.jpg`}
          alt={inputs.cityKey}
          retry={3}
        />
      ) : (
        <div className="headerPlaceholder" />
      )}
      {config.showHeader ? (
        <Header
          componentStates={componentStates}
          setAgentOverlay={props.setOverlay}
          setDays={handleSetDays}
        />
      ) : null}
      {config.showProgression ? (
        <ProgressionBar
          length={travelPlanner.content?.[0]?.config?.steps?.length || 0}
        />
      ) : null}
      {config.fullScreen && showMobileMap ? (
        <div className="backButton" onClick={() => setShowMobileMap(false)}>
          <Picto iconKey="arrow-fr" />
          <span>
            {t(`travelPlaner.Travel.sites.back${showAllSites ? "Sites" : ""}`)}
          </span>
        </div>
      ) : undefined}
      <div
        className={`travelPlanerResultsContent ${
          config.fullScreen
            ? showMobileMap
              ? "showMobileMap"
              : "showMobileDays"
            : ""
        }`}
      >
        <div className="content-wrapper">
          {!config.fullScreen ? (
            <div className="content">
              <a
                className="backButton backButtonHome"
                href={travelPlanner?.path?.current || "/guide"}
              >
                <Picto iconKey={"arrow-left"} />
              </a>
              <Beta
                isInline={config.inlineBeta}
                title={t("travelPlaner.Travel.beta.title") || ""}
                content={t("travelPlaner.Travel.beta.content")}
              />
              <h2 className="title">{t("travelPlaner.Travel.title")}</h2>
              {config.showTripLength ? (
                <div className="tripLength">
                  {t("travelPlaner.Travel.tripLength", {
                    count: inputs.tripLength || 1,
                  })}
                </div>
              ) : null}
              {config.showDescription ? (
                <h4 className="description">
                  {t("travelPlaner.Travel.description")}
                </h4>
              ) : null}
            </div>
          ) : showAllSites ? undefined : (
            <div className="leftHeader">
              <a
                className="backButton"
                href={travelPlanner?.path?.current || "/guide"}
              >
                <Picto iconKey={"arrow-left"} />
              </a>
              <div className="leftHeaderContent">
                <div className="travelPlaceTitle">
                  <Beta
                    title={t("travelPlaner.Travel.beta.title") || ""}
                    content={t("travelPlaner.Travel.beta.content")}
                  />
                  {results.inputs?.search || results.inputs?.searchMap}
                </div>
                <div className="travelDatesSubtitle">{getFormattedDates()}</div>
                <div className="travelPlanerSummary">
                  {(inputsOfTravel.search || inputsOfTravel.searchMap) && (
                    <div>
                      <span>{`${t("travelPlaner.Search.title")} :`}</span>
                      <span> {inputsOfTravel.search}</span>
                    </div>
                  )}
                  {inputsOfTravel.pace && (
                    <div>
                      <span>{t("travelPlaner.Pace.title")} :</span>
                      <span>
                        {t(`travelPlaner.Pace.${inputsOfTravel.pace}`)}
                      </span>
                    </div>
                  )}
                  {inputsOfTravel.times && (
                    <div>
                      <span>{t("travelPlaner.Times.title")} :</span>
                      <span>
                        {t("travelPlaner.Times.title")} : $
                        {t(`travelPlaner.Times.${inputsOfTravel.times}`)}
                      </span>
                    </div>
                  )}
                  {inputsOfTravel.budget && (
                    <div>
                      <span>{t("travelPlaner.Budget.title")} :</span>
                      <span>
                        {t(`travelPlaner.Budget.${inputsOfTravel.budget}`)}
                      </span>
                    </div>
                  )}
                  {inputsOfTravel.lodging &&
                    (inputsOfTravel.lodging.address ||
                      inputsOfTravel.lodging.hotel) && (
                      <div>
                        <span>{t("travelPlaner.Lodging.title")} :</span>
                        <span>
                          {inputsOfTravel.lodging.address.name ||
                            inputsOfTravel.lodging.hotel}
                        </span>
                      </div>
                    )}
                  {inputsOfTravel.dayNumber && (
                    <div>
                      <span>
                        {t("travelPlaner.DayNumber.title")} : $
                        {inputsOfTravel.dayNumber}
                      </span>
                    </div>
                  )}
                  {inputsOfTravel.composition && (
                    <div>
                      <span>{t("travelPlaner.Composition.title")} :</span>
                      <span>
                        {t(
                          `travelPlaner.Composition.${inputsOfTravel.composition}`
                        )}
                      </span>
                    </div>
                  )}
                  {inputsOfTravel.startDate && (
                    <div>
                      <span>{t("travelPlaner.StartDate.title")} :</span>
                      <span>{getFormattedDates()}</span>
                    </div>
                  )}
                  {selectedInterests.length > 0 && (
                    <div>
                      <span>{t("travelPlaner.Interests.title")} :</span>
                      <span>
                        {selectedInterests
                          .map((i) => t(`travelPlaner.Interests.keys.${i}`))
                          .join(", ")}
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
          {config.showRestaurants && !config.fullScreen ? (
            <Restaurants
              requirements={requirements}
              restaurants={restaurants}
              dayOfTheWeek={dayOfTheWeek}
            />
          ) : null}
        </div>
        {config.showDays && !config.fullScreen ? (
          <DaysHeader showWhereToSleep={lodging?.length > 0} />
        ) : null}
        {day ? (
          <Fragment>
            {(!isMobile ||
              !config.fullScreen ||
              (isMobile && showMobileMap)) && (
              <TravelCustomMap
                day={day}
                journey={journey}
                config={config}
                requirements={requirements}
                inputs={inputs}
                doAction={handleMapAction}
              />
            )}
            {showAllSites ? (
              <SitesExplorator
                config={config}
                requirements={requirements}
                onBack={() => setShowAllSites(false)}
                setShowMobileMap={setShowMobileMap}
                onChange={handleChangeTravelPoint}
                onDelete={handleDeleteTravelPoint}
                days={Object.keys(formattedData).map(
                  (_, i) => `${t("travelPlaner.Travel.day")} ${i + 1}`
                )}
              />
            ) : (
              <TravelDays
                config={config}
                isEditable={
                  config.fullScreen &&
                  getCookies()?.cibler_id === journey.ciblerId
                }
                requirements={requirements}
                data={formattedData?.[day]}
                day={day}
                selectedActivities={selectedActivities?.[day]}
                handleChangeActivity={handleChangeActivity}
                restaurants={restaurants}
                lodgingPoint={lodgingPoint}
                lodgings={lodgings}
                dayOfTheWeek={dayOfTheWeek}
                dayIndex={Object.keys(formattedData).indexOf(day) + 1}
                cityKey={inputs.cityKey}
              />
            )}
          </Fragment>
        ) : null}
        {config.showDays && config.fullScreen && !showAllSites ? (
          <DaysHeader
            showWhereToEat={showFullScreenRestaurants}
            showWhereToSleep={showFullScreenLodgings}
          />
        ) : null}
        {config.fullScreen && !showAllSites ? (
          <Footer
            trip={trip}
            componentStates={componentStates}
            setShowMobileMap={setShowMobileMap}
            requirements={requirements}
          />
        ) : undefined}
      </div>
      {showReview && !config.fullScreen ? (
        <Review
          requirements={requirements}
          label={t("travelPlaner.Travel.review")}
          thanksText={t("travelPlaner.Travel.reviewThanks")}
          setShowFeedback={setShowFeedback}
        />
      ) : null}
      {config.hideChatAgent || config.chatAgentInHeader ? undefined : (
        <ChatAgent
          key={
            JSON.stringify(formattedData) + JSON.stringify(selectedActivities)
          }
          requirements={requirements}
          agentId={props.agentId}
          context={simplifyTrip(formattedData, selectedActivities)}
          setOverlay={props.setOverlay}
        />
      )}
      {showFeedback && props.feedbackUrl && (
        <FeedbackPopin
          callback={() => setShowFeedback(false)}
          url={props.feedbackUrl.replace("{{id}}", id)}
        />
      )}
    </div>
  );
};

export const simplifyTrip = (
  trip: any,
  selectedActivities: any = {}
): string => {
  // Create a deep copy of the trip object
  let tripForAgent = JSON.parse(JSON.stringify(trip));

  for (let day in tripForAgent) {
    for (let time in tripForAgent[day]) {
      const activityIndex = selectedActivities?.[day]?.[time] || 0;
      let firstChoice = tripForAgent[day][time]?.value?.[activityIndex];
      if (!firstChoice) continue;

      // Remove unwanted properties
      delete firstChoice.urlimg;
      delete firstChoice.gifts;
      delete firstChoice.matching;
      delete firstChoice.tourinsoft_id;
      delete firstChoice.longitude;
      delete firstChoice.latitude;
      delete firstChoice.tca_categ;

      // Keep only the first choice for each time
      tripForAgent[day][time] = firstChoice;
    }
  }

  return JSON.stringify(tripForAgent);
};

interface DaysProps {
  hideAI?: boolean;
  showWhereToEat?: boolean;
  showWhereToSleep?: boolean;
}

export const DaysHeader = ({
  hideAI = false,
  showWhereToEat = true,
  showWhereToSleep = true,
}: DaysProps) => {
  const { t, i18n } = useTranslation();
  const {
    agentId,
    setOverlay,
    config,
    inputs,
    requirements,
    formattedData,
    selectedActivities,
    day,
    setDay,
    selectedPart,
    handleGoTo,
  } = useContext(TravelPlanerResultsContext);

  const fakeDays = Array.from({ length: inputs?.tripLength }, (_, i) => ({
    key: Object.keys(formattedData)[i],
    index: i + 1,
    label: config.fullScreen
      ? `${t("travelPlaner.Travel.day")} ${i + 1}`
      : moment(inputs.startDate).add(i, "day").format("DD/MM"),
  }));

  return (
    <div className="days">
      {config.hideChatAgent ||
      !config.chatAgentInHeader ||
      !config.fullScreen ||
      hideAI ? undefined : (
        <ChatAgent
          key={
            JSON.stringify(formattedData) + JSON.stringify(selectedActivities)
          }
          requirements={requirements}
          agentId={agentId}
          context={simplifyTrip(formattedData, selectedActivities)}
          setOverlay={setOverlay}
          withLabel
          hideHighlight
        />
      )}
      <div className="days-container">
        {fakeDays.map((d) => (
          <button
            key={`daylabel_${d.index}`}
            onClick={() => {
              if (!!!d.key) return;
              setDay(d.key);
              handleGoTo("dayPoints");
            }}
            className={d.key ? (d.key === day ? "selected" : "") : "skeleton"}
          >
            {config.fullScreen
              ? `${t("travelPlaner.Travel.day")} ${d.index}`
              : moment(inputs.startDate)
                  .add(d.index, "day")
                  .format(
                    i18n.language.split("_")?.[0] === "fr" ? "DD/MM" : "MM/DD"
                  )}
          </button>
        ))}
        {showWhereToEat ? (
          <button
            key="daylabel_eat"
            className={selectedPart === "restaurants" ? "selected" : ""}
            onClick={() => handleGoTo("restaurants")}
          >
            {t("travelPlaner.Travel.whereToEat")}
          </button>
        ) : undefined}
        {showWhereToSleep ? (
          <button
            key="daylabel_sleep"
            className={selectedPart === "lodgings" ? "selected" : ""}
            onClick={() => handleGoTo("lodgings")}
          >
            {t("travelPlaner.Travel.whereToSleep")}
          </button>
        ) : undefined}
      </div>
    </div>
  );
};

const getBarycenter = (
  travelData: any
): { lat: number; lon: number } | null => {
  try {
    const data = Object.keys(travelData)?.map((key) => travelData[key]);
    if (!data.length) return null;

    const points = data.reduce((acc: any, d: any) => {
      Object.keys(d).forEach((k) => {
        acc.push(d[k][0]);
      });
      return acc;
    }, []);
    if (!points.length) return null;

    const latitudes = points.map((p: any) => p.latitude);
    const longitudes = points.map((p: any) => p.longitude);
    const minLat = Math.min(...latitudes);
    const maxLat = Math.max(...latitudes);
    const minLon = Math.min(...longitudes);
    const maxLon = Math.max(...longitudes);

    return {
      lat: (minLat + maxLat) / 2,
      lon: (minLon + maxLon) / 2,
    };
  } catch (e) {
    console.error("Error getting barycenter", e);
    return null;
  }
};

const preloader = async (
  data: any,
  requirements: RequirementsProps,
  storybookData?: {
    config: TravelPlannerResultsConfigType;
    travelPlanner: {
      path: {
        current: string;
      };
      content: [
        {
          config: TravelPlannerConfigType;
        }
      ];
    };
  }
) => {
  if (!requirements.parameters.length) return null;
  let id = requirements.parameters[0];
  const journey = await getItinerary(id, requirements);

  let { config, travelPlanner } = storybookData || {};

  if (!storybookData) {
    const client = getSanityClient(requirements);
    const query = `*[_type == "page" && (path.current=="${requirements.path}") && !(_id in path("drafts.**"))][0]`;
    const results = await client.fetch(query);
    config = results?.content?.[0]?.config || {};
    const travelPlannerRef = config?.travelPlannerRef?._ref;

    travelPlanner = travelPlannerRef
      ? await client.fetch(
          `*[_id == "${travelPlannerRef}" && !(_id in path("drafts.**"))][0]`
        )
      : undefined;
  }

  const { travelData } = journey;
  const { lat: tpLat, lon: tpLon, radius } = travelData?.inputs || {};
  const { lat, lon } = getBarycenter(travelData?.trip || {}) || {
    lat: tpLat,
    lon: tpLon,
  };

  const restaurants = config.showRestaurants
    ? await getNearby(id, "restaurant", requirements, lat, lon)
    : [];
  const lodgings = config.showLodgings
    ? await getNearby(id, "hotel", requirements, lat, lon)
    : [];

  const sites =
    config.mapLink?.action === "sites"
      ? await makeAnAlgoliaSearch(
          { ...data, radius: radius || 10000 },
          requirements,
          [],
          "NOT categslug:ou-sejourner AND NOT categslug:a-table AND NOT categslug:commerce",
          lat,
          lon
        )
      : [];

  return {
    journey,
    restaurants,
    lodgings,
    sites,
    travelPlanner,
  };
};

const RendererWithProviders = (props: TravelPlanerResultsProps) => {
  return (
    <DirectionProvider>
      <TravelPlanerResultsProvider
        agentId={props.agentId}
        setOverlay={props.setOverlay}
        initialJourney={props.componentStates.journey}
        config={props.config}
        requirements={props.requirements}
        initialSites={props.componentStates.sites}
        siteConfiguration={props.siteConfiguration}
      >
        <Renderer {...props} />
      </TravelPlanerResultsProvider>
    </DirectionProvider>
  );
};

const TravelPlanerResults = { Renderer: RendererWithProviders, preloader };

export default TravelPlanerResults;
export { Renderer as RendererWithProviders, preloader };
