import React, { useState, useEffect } from "react";
import { useTranslation } from "../../tools/i18n";
import {Picto} from "../Picto";
import StaticCarousel from "../Carousel/StaticCarousel";
import { fetchArticlesData } from "../../tools/apiTools";

interface Article {
    title: string;
    url: string;
    image_url: string;
    category: string;
    location: string;
}

interface ArticlesProps {
    requirements: any;
    cityKey: string;
}

const Articles: React.FC<ArticlesProps> = ({ requirements, cityKey }) => {
    const { t } = useTranslation();
    const [articles, setArticles] = useState<Article[]>([]);
    const API_KEY = process.env.REACT_APP_FRANCE_API_KEY;
    const SECRET_KEY: string = process.env.REACT_APP_FRANCE_SECRET_KEY;

    useEffect(() => {
        fetchArticlesData(cityKey, API_KEY, SECRET_KEY)
            .then(data => {
                if(data) {
                    setArticles(data.map((article, index) => ({
                        key: `article_${index}`,
                        title: article.title,
                        image: article.image_url,
                        noNote: true,
                        url: article.url,
                        category: article.category,
                        place: article.location,
                    })));
                }
            }).catch(e => console.error(e));
    }, [cityKey]);

    return (
        <>
            {articles.length > 0 ? (
                <div className="Articles">
                    <div className="articles-header">
                        <Picto iconKey={"bed"} width="28" height="28" />
                        <span>{t('travelPlaner.Travel.articles.title')}</span>
                    </div>
                    <StaticCarousel
                        label=""
                        subLabel=""
                        slides={articles}
                        requirements={requirements}
                    />
                </div>
            ) : null}
        </>
    );
};

export default Articles;