export default {
  key: "ileetvilaineca",
  canonicalUrl: "avantageilleetvilaine.fr",
  datalayerId: "GTM-N7FRS7X", // to change
  customerId: 690,
  icon: "illetvilaineca.ico",
  headerLogo: "illeetvilaineca.png",
  footerLogo: "illeetvilaineca.svg",
  axeptioClientId: "",
  axeptioCookiesVersion: "avantageilleetvilaine.fr",
  clientUrl: "https://client.avantageilleetvilaine.fr",
  proUrl: "https://pro.avantageilleetvilaine.fr",
  sanity: {
    base: {
      projectId: "apphfa2v",
      dataset: "production",
      apiVersion: "2022-12-01",
      useCdn: true,
    },
    preview: {
      useCdn: false,
      withCredentials: true,
      token: "skQhPLMKo8LOvajTq4GPS6FoTHZ7nCHKQ3Ka9tstV1DsPnW0duJYIUWsnTjsjLFjWIFVG2h7VkbHqEHKNxdhxlGIoq8fuBCwHoGmADKSQZ0XFGUZ6GCPrRn1LXJaSV3CHoVoKKA2pNHY8sPJ9tLZEzIWHPK6vUS8xhpkClYWMVojkULyPY1Q",
    },
  },
  dev: {
    clientUrl: "https://client.avantageilleetvilaine.fr",
    proUrl: "https://pro.avantageilleetvilaine.fr",
  },
};