import React, {Fragment, useContext, useState, useEffect} from 'react';
import {RequirementsProps} from "../../tools/context";
import {ResponsiveImage} from "../ResponsiveImage";
import {getDiscountTags} from "../Carousel/OfferSlide";
import {Picto, PictoProps} from "../Picto";
import StarNote from "../StarNote";
import {useTranslation} from "../../tools/i18n";
import { DirectionContext } from './DirectionContext';
import { Profile } from '../../tools/mapbox/apiTypes/direction';
import PictoButton from './PictoButton';
import Restaurants, { RestaurantType } from './Restaurants';
import Articles from './Articles';
import {TravelPlannerResultsConfigType} from "./types";
import CustomImage from "../CustomImage";
import {TravelPlanerResultsContext} from "./TravelPlanerResultsContext";
import EditJourney from "./EditJourney";
import {Button} from "../Button";
import i18next from "i18next";

interface TravelPointData {
  value: string;
  key: string;
}

export interface TravelPointProps {
  config: TravelPlannerResultsConfigType;
  isEditable?: boolean;
  data: any
  i: number
  requirements: RequirementsProps
  selectedActivity: number
  onChangeActivity: (direction: ChangeActivityDirection) => void
  isLastPoint?: boolean;
}

export type ChangeActivityDirection = 'prev' | 'next'



const getKnowMoreLink = (requirements: RequirementsProps, poi: any) => {
  switch (requirements?.config?.key) {
    case 'martinique':
      return i18next.language.split('_')?.[0] === 'fr'
        ? `https://www.martinique.org/fr/node/${poi.tourinsoft_id}`
        : `https://www.martinique.org/en/node/${poi.tourinsoft_id}`;
    default:
      return `/etablissement/${poi.id}`
  }
}

const NavigationLink = ({ latitude, longitude }) => {
  const {t} = useTranslation();
  const [showPopup, setShowPopup] = useState(false);

  const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    setShowPopup(prevState => !prevState);
  };

  const navigations = [
    {
      label: t('travelPlaner.Travel.googleMaps'),
      url: `https://www.google.com/maps/dir/?api=1&destination=${latitude},${longitude}`,
      imageUrl: '/logo/maps.png'
    },
    {
      label: t('travelPlaner.Travel.waze'),
      url: `https://waze.com/ul?ll=${latitude}%2C${longitude}&navigate=yes`,
      imageUrl: '/logo/waze.png'
    },
    {
      label: t('travelPlaner.Travel.applePlans'),
      url: `https://maps.apple.com/?daddr=${latitude},${longitude}`,
      imageUrl: '/logo/plans.png'
    },
  ];

  return (
    <div className={`navigation ${showPopup ? 'open' : ''}`}>
      <button onClick={handleClick}>
        {t('travelPlaner.Travel.travelTo')}
        <Picto iconKey="caret-right" />
      </button>
      {showPopup && navigations?.length && (
        <div className="navigationPopup">
          <div className="items">
            {navigations.map((nav, index) => (
              <a key={index} href={nav.url} target="_blank" rel="noopener noreferrer">
                <div>
                  <img src={nav.imageUrl} alt={nav.label} />
                  {nav.label}
                </div>
                <Picto iconKey="caret-right" />
              </a>
            ))}
          </div>
          <button onClick={() => setShowPopup(false)}>{t('travelPlaner.Travel.cancel')}</button>
        </div>
      )}
    </div>
  );
};

export const TravelPoint = (props: TravelPointProps) => {
  const {
    config,
    data,
    i,
    requirements,
    selectedActivity,
    onChangeActivity
  } = props;
  const {t} = useTranslation();
  const promo = getDiscountTags(data[selectedActivity]?.gifts);
  const openingHours = data[selectedActivity]?.opening_hours
    ? JSON.parse(data[selectedActivity]?.opening_hours)
    : null;
  const isOpenSunday = openingHours
    ? openingHours[6] !== 'dimanche: Fermé'
    : false;

  return <div className="travelPoint" id={`travelPoint_${i}`}>
    <div className="indexLine">
      <span>{i+1}</span>
      <div className="line" />
    </div>
    <div className="content">
      <div className="name">{data[selectedActivity].store_name}</div>
      {config.showStoreDescription
        ? <div className="description">{data[selectedActivity].description || null}</div>
        : null
      }
      <div className="imageWrapper">
        {(data[selectedActivity].urlimg && (
          data[selectedActivity].urlimg.endsWith('.png') ||
          data[selectedActivity].urlimg.endsWith('.jpg') ||
          data[selectedActivity].urlimg.endsWith('.jpeg') ||
          data[selectedActivity].urlimg.endsWith('.webp')
        )) || data[selectedActivity].tca_categ
          ? <ResponsiveImage
            requirements={requirements}
            src={data[selectedActivity].urlimg}
            alt={data[selectedActivity].urlimg}
            fallback={config.siteFallbackImage?.image || ""}
          />
          : <CustomImage
              requirements={requirements}
              image={config.siteFallbackImage?.image || ""}
              alt={config.siteFallbackImage?.alt || ""}
              credit={config.siteFallbackImage?.credit || ""}
            />
        }
        {data[selectedActivity].time
          ? <div className="time">{data[selectedActivity].time}</div>
          : null
        }
      </div>
      <div className="contentBody">
        {config.showOpenSunday && isOpenSunday
          ? <span className="openSunday">{t('travelPlaner.Travel.openSunday')}</span>
          : null
        }
        {config.showNote && !!data[selectedActivity].shop_grade
          ? <div className="rate">
            <StarNote note={parseFloat(data[selectedActivity].shop_grade)} show_note={false}/>
            <span>{t('travelPlaner.Travel.noteReview', {count: parseInt(data[selectedActivity].review_nb)})}</span>
          </div>
          : null
        }
        {promo.map(p => <div className="promo"><Picto iconKey={'circle-percent'}/><span>{p}</span></div>)}
        {!!data[selectedActivity].id
          ? <a className="link" target="_blank" href={getKnowMoreLink(requirements, data[selectedActivity])}>
            <span>{t('travelPlaner.Travel.knowMore')}</span>
            <Picto iconKey="caret-right"/>
          </a>
          : null
        }
        <NavigationLink
          latitude={data[selectedActivity].latitude}
          longitude={data[selectedActivity].longitude}
        />
      </div>
      {data.length > 1
        ? <div className="activitiesSwitch">
          <button onClick={() => onChangeActivity('prev')}>
            <Picto iconKey="caret-left"/>
          </button>
          <span>{t('travelPlaner.Travel.changeActivity')}</span>
          <button onClick={() => onChangeActivity('next')}>
            <Picto iconKey="caret-right"/>
          </button>
        </div>
        : null
      }
    </div>
  </div>
}

export const FullScreenTravelPoint = (props: TravelPointProps) => {
  const {
    data,
    i,
    requirements,
    selectedActivity,
    isLastPoint,
  } = props;
  const {t} = useTranslation();
  const {config} = useContext(TravelPlanerResultsContext);
  const [clicked, setClicked] = useState(true);

  const handleToggle = () => {
    setClicked((prev) => !prev);
  };
  const d = data[selectedActivity];
  const isActivity = d.type === 'activity';
  const img = d.urlimg;
  const fbImage = config.siteFallbackImage || {};

  return (
    <div className="fullScreenTravelPoint" id={`fullScreenTravelPoint_${i}`}>
      <div className={`${clicked ? "indexLine open" : "indexLine"} ${isLastPoint ? "last" : ""}`}>
        <span>{i+1}</span>
        <div className="line" />
      </div>
      <div className="content">
        <div className={clicked ? "contentHeader open" : "contentHeader"}>
          <div className='titleContainer'>
            <div className='title' onClick={handleToggle}>
              <Picto iconKey='arrow-fr'/>
              <div className="name">{d.store_name}</div>
            </div>
            {clicked ? undefined : <div className="separation"/>}
          </div>
        </div>
        <div className={clicked ? "contentBody open" : "contentBody"}>
          <div className="imageWrapper">
            {(img && (img.endsWith('.png') || img.endsWith('.jpg') || img.endsWith('.jpeg') || img.endsWith('.webp'))) || d.tca_categ
              ? <ResponsiveImage
                  requirements={requirements}
                  src={img}
                  alt={img}
                  fallback={fbImage.image || ""}
                />
              : <CustomImage
                  requirements={requirements}
                  image={fbImage.image || ""}
                  alt={fbImage.alt || ""}
                  credit={fbImage.credit || ""}
                />
            }
          </div>
          <p className="description">{d.description || null}</p>
          {(!!d.id && !isActivity) || (isActivity && d.site)
            ? <a
                className="link"
                target="_blank"
                href={isActivity ? d.site : getKnowMoreLink(requirements, d)}
                rel="noreferrer"
              >
                <span>{t('travelPlaner.Travel.knowMore')}</span>
                <Picto iconKey="caret-right"/>
              </a>
            : null
          }
          <NavigationLink
            latitude={d.latitude}
            longitude={d.longitude}
          />
        </div>
      </div>
    </div>
  )
}

export interface TravelDaysProps {
  config: TravelPlannerResultsConfigType;
  isEditable?: boolean;
  requirements: RequirementsProps;
  data: any;
  day: string;
  selectedActivities: {[key: string]: number};
  handleChangeActivity: (direction: ChangeActivityDirection, key: string) => void;
  restaurants: RestaurantType[];
  lodgingPoint?: any;
  lodgings: RestaurantType[];
  dayOfTheWeek: number;
  dayIndex?: number;
  cityKey: string;
}

export const TravelDays = (props: TravelDaysProps) => {
  const {config, isEditable, lodgingPoint, data} = props;
  const { currentProfile, selectedRoute } = useContext(DirectionContext);
  const {
    setShowAllSites,
    setShowMobileMap,
  } = useContext(TravelPlanerResultsContext);
  const {t} = useTranslation();

  function renderTravelPoint(
    data: {
      key: string;
      value: TravelPointProps["data"];
      i: number;
    },
  ) {
    const leg = selectedRoute?.legs?.[data.i];
    const isLastPoint = data.i === (props.data.length - 1);

    return (
      <Fragment key={`${encodeURIComponent(props.day)}_key_${data.i}`}>
        {config.fullScreen
          ? <FullScreenTravelPoint
              config={config}
              isEditable={isEditable}
              data={data.value}
              requirements={props.requirements}
              i={data.i}
              selectedActivity={props.selectedActivities[data.key]}
              onChangeActivity={(direction) =>
                props.handleChangeActivity(direction, data.key)
              }
              isLastPoint={isLastPoint}
            />
          : <TravelPoint
              config={config}
              data={data.value}
              requirements={props.requirements}
              i={data.i}
              selectedActivity={props.selectedActivities[data.key]}
              onChangeActivity={(direction) =>
                props.handleChangeActivity(direction, data.key)
              }
            />
        }
        {currentProfile != null && leg != null && (
          <LegInfo 
            profile={currentProfile} 
            distanceMeters={leg.distance}
            durationSeconds={leg.duration}
          />
        )}
      </Fragment>
    );
  }

  const showFullScreenRestaurants = config.fullScreen && config.showRestaurants && (props.restaurants?.length || 0) > 0;
  const showFullScreenLodgings = config.fullScreen && config.showLodgings && (props.lodgings?.length || 0) > 0 && !lodgingPoint;

  const processData = (data: TravelPointData[]) => {
    if (!data || !Array.isArray(data)) return [];
    return data.reduce((acc, item) => acc.concat(item.value), []);
  };

  useEffect(() => {
    processData(data);
  }, [data, config.minNumberOfPoi]);

  const allItems = processData(props.data);

  return (
      <div className="travelDays">
        {config.showAIMention
            ? <div className="aiMention">
              <PictoButton iconKey="ai-mention" size="small" />
              <span className="aiMentionTitle">{t("travelPlaner.Travel.beta.content")}</span>
            </div>
            : undefined
        }
        {showFullScreenRestaurants
            ? <Restaurants
                requirements={props.requirements}
                restaurants={props.restaurants}
                dayOfTheWeek={props.dayOfTheWeek}
            />
            : null
        }
        {config.showArticles
            ? <Articles
                requirements={props.requirements}
                cityKey={props.cityKey}
            />
            : null
        }
        {config.fullScreen || isEditable
            ? <div className="dayHeader">
              <div className='title' id='dayPoints'>
                <Picto iconKey='itinerary-fr' width="32" height="32" />
                {t("travelPlaner.Travel.day")} {props.dayIndex}
              </div>
              {isEditable
                  ? <EditJourney />
                  : undefined
              }
            </div>
            : undefined
        }
        <div className="travelPoints" id="travelPoints">
          {props.data?.map((data: any, i: number) => renderTravelPoint({ ...data, i }))}
        </div>
        {config.fullScreen && isEditable ? (
            allItems.length < config.minNumberOfPoi && config.minNumberOfPoi > 0 && typeof config.minNumberOfPoi !== "undefined" ? (
                <>
                  <em className="enoughPoi">
                    { t("travelPlaner.Travel.enoughPoi") }
                    <Button
                        className="addStep addStepMore"
                        onClick={() => {
                          window.scrollTo({ top: 0 });
                          setShowAllSites(true);
                          setShowMobileMap(false);
                        }}
                    >
                      <div className="iconWrapper">
                        <Picto iconKey="plus" />
                      </div>
                      {t("travelPlaner.Travel.addStep")}
                    </Button>
                  </em>
                </>
            ) : (
                <Button
                    className="addStep addStepMore"
                    onClick={() => {
                      window.scrollTo({ top: 0 });
                      setShowAllSites(true);
                      setShowMobileMap(false);
                    }}
                >
                  <div className="iconWrapper">
                    <Picto iconKey="plus" />
                  </div>
                  {t("travelPlaner.Travel.addStep")}
                </Button>
            )
        ): (
            null
        )
        }
        {showFullScreenLodgings
            ? <Restaurants
                requirements={props.requirements}
                restaurants={props.lodgings}
                dayOfTheWeek={props.dayOfTheWeek}
                lodgings
            />
            : null
        }
      </div>
  );
}

type LegInfoProps = {
  profile: Profile;
  durationSeconds: number;
  distanceMeters: number;
};

function LegInfo(props: LegInfoProps) {
  const {distanceMeters, durationSeconds} = props;

  const formattedDistance = distanceMeters < 1_000
    ? `${distanceMeters.toFixed(0)} m`
    : `${(distanceMeters / 1_000).toFixed(1)} km`;

  const hours = Math.floor(durationSeconds / 3_600);
  const minutes = Math.floor((durationSeconds - hours * 3_600) / 60);
  const formattedDuration = durationSeconds < 60
    ? `${durationSeconds} s`
    : durationSeconds < 3_600
    ? `${(durationSeconds / 60).toFixed(0)} min`
    : `${hours} h ${minutes} min`;

  return (
    <div className="travelDayLegInfo">
      <div className="distance">
        <Picto iconKey="arrow-up" />
        <p>{formattedDistance}</p>
        <Picto iconKey="arrow-down" />
      </div>
      <div className="duration">
        <DirectionProfilePicto profile={props.profile} fill='black' height='30' />
        <p>
          <span className="value">{formattedDuration}</span>{" "}
            <DirectionProfileText profile={props.profile} />
        </p>
      </div>
    </div>
  );
}

function DirectionProfileText(props: { profile: Profile }) {
  return <span>{DirectionProfileString(props.profile)}</span>;
}

function DirectionProfileString(profile: Profile) {
  const {t} = useTranslation();

  switch (profile) {
    case Profile.CYCLING:
      return t('travelPlaner.Travel.Move.bike');
    case Profile.DRIVING_TRAFFIC:
      return t('travelPlaner.Travel.Move.car');
    case Profile.DRIVING:
      return t('travelPlaner.Travel.Move.car');
    case Profile.WALKING:
      return t('travelPlaner.Travel.Move.feet');
    default:
      throw Error(
        "Invalid direction profile make sure profile isn't undefined or null."
      );
  }
}

function DirectionProfilePicto({profile, ...pictoProps}: { profile: Profile } & PictoProps){
  switch (profile) {
    case Profile.CYCLING:
      return <Picto iconKey="bike" {...pictoProps} />;
    case Profile.DRIVING_TRAFFIC:
      return <Picto iconKey="car" {...pictoProps} />;
    case Profile.DRIVING:
      return <Picto iconKey="car" {...pictoProps} />;
    case Profile.WALKING:
      return <Picto iconKey="pedestrian" {...pictoProps} />;
    default:
      throw Error(
        "Invalid direction profile make sure profile isn't undefined or null."
      );
  }
}