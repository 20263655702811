import React from "react";
import {RequirementsProps} from "../../tools/context";

export interface LogoProps {
  className?: string
  requirements: RequirementsProps
}

const Logo = ({requirements, className=null, location="header"}) => {
  const logo = requirements && requirements.config && (location == "header" ? requirements.config.headerLogo : requirements.config.footerLogo);

  return <a href="/" className={className}>
    <img src={`/logo/${logo}`}/>
  </a>
}

export default Logo;