import React from 'react';

import {ArrowLeft} from "./ArrowLeft";
import {ArrowRight} from "./ArrowRight";
import {Bin} from "./Bin";
import {BurgerMenu2} from "./BurgerMenu2";
import {BurgerMenu} from "./BurgerMenu";
import {Calendar} from "./Calendar";
import {CaretDown} from "./CaretDown";
import {CaretLeft} from "./CaretLeft";
import {CaretRight} from "./CaretRight";
import {CaretUp} from "./CaretUp";
import {CircleCross} from "./CircleCross";
import {CirclePlay} from "./CirclePlay";
import {Clip} from "./Clip";
import {Clock} from "./Clock";
import {Cross} from "./Cross";
import {Cursors} from "./Cursors";
import {Dots} from "./Dots";
import {Download} from "./Download";
import {EnvelopeNewsletter} from "./EnvelopeNewsletter";
import {Envelope} from "./Envelope";
import {Facebook} from "./Facebook";
import {Info} from "./Info";
import {Instagram} from "./Instagram";
import {Like} from "./Like";
import {List} from "./List";
import {Location} from "./Location";
import {Logo} from "./Logo";
import {Navigation} from "./Navigation";
import {PadlockClosed} from "./PadlockClosed";
import {PadlockOpen} from "./PadlockOpen";
import {Phone} from "./Phone";
import {Place} from "./Place";
import {Printer} from "./Printer";
import {Quote} from "./Quote";
import {Search} from "./Search";
import {Star} from "./Star";
import {Target} from "./Target";
import {Twitter} from "./Twitter";
import {User} from "./User";
import {Youtube} from "./Youtube";
import {OuSejourner} from "./ouSejourner";
import {Monuments} from "./monuments";
import {ATable} from "./aTable";
import {Producteurs} from "./producteurs";
import {Voyages} from "./voyages";
import {Loisirs} from "./loisirs";
import {Check} from "./Check";
import {GiftCard} from "./GiftCard";
import {Address} from "./Address";
import {City} from "./City";
import {Country} from "./Country";
import {Region} from "./Region";
import {MaisonJardin} from "./MaisonJardin";
import {Commerce} from "./Commerce";
import {Mode} from "./Mode";
import {BeauteSante} from "./BeauteSante";
import {Alimentaire} from "./Alimentaire";
import {AdvantagePromo} from "./AdvantagePromo";
import {HowtoAdvantage1} from "./HowtoAdvantage1";
import {HowtoAdvantage2} from "./HowtoAdvantage2";
import {HowtoAdvantage3} from "./HowtoAdvantage3";
import {CircleCheck} from './CircleCheck';
import {TravelCouple} from "./TravelCouple";
import {TravelFamily} from "./TravelFamily";
import {TravelFriends} from "./TravelFriends";
import {TravelEco} from "./TravelEco";
import {TravelMedium} from "./TravelMedium";
import {TravelExpensive} from "./TravelExpensive";
import {CircleI} from "./CircleI";
import {CirclePercent} from "./CirclePercent";
import {CircleArrowRight} from "./CircleArrowRight";
import {Loader} from './Loader';
import {Google} from "./Google";
import {Apple} from "./Apple";
import {ChatIcon} from "./ChatIcon";
import {GuideIcon} from "./GuideIcon";
import {ThumbWhite} from "./ThumbWhite";
import {ThumbBlack} from "./thumbBlack";
import {Plus} from "./Plus";
import {Minus} from "./Minus";
import {CaretRightFilled} from "./CaretRightFilled";
import {LikeCircle} from "./LikeCircle";
import {DislikeCircle} from "./DislikeCircle";
import {TravelEcoNewUI} from "./TravelEcoNewUI";
import {TravelMediumNewUI} from "./TravelMediumNewUI";
import {TravelExpensiveNewUI} from "./TravelExpensiveNewUI";
import { HeartIcon } from './Heart';
import {SwitchLeft} from "./SwitchLeft";
import {SwitchRight} from "./SwitchRight";
import {TravelEcoNewUIV2} from "./TravelEcoNewUIV2";
import {TravelMediumNewUIV2} from "./TravelMediumNewUIV2";
import {TravelExpensiveNewUIV2} from "./TravelExpensiveNewUIV2";
import {ArrowUp} from './ArrowUp';
import {ArrowDown} from './ArrowDown';
import {Car} from './Car';
import {Bike} from './Bike';
import {Pedestrian} from './Pedestrian';
import {Cutlery} from "./Cutlery";
import {Share} from "./Share";
import {LikeCircleFilled} from "./LikeCircleFilled";
import {DislikeCircleFilled} from "./DislikeCircleFilled";
import {Euro} from "./Euro";
import {Bus} from "./Bus";
import {TravelSoloV2} from "./TravelSoloV2";
import {TravelCoupleV2} from "./TravelCoupleV2";
import {TravelFamilyV2} from "./TravelFamilyV2";
import {TravelFriendsV2} from "./TravelFriendsV2";
import {Flag} from "./Flag";
import {Periods} from "./fr/Periods";
import {Dates} from "./fr/Dates";
import { AiMention } from './AiMention';
import {Walker} from "./fr/Walker";
import {BackPacker} from "./fr/BackPacker";
import {Runner} from "./fr/Runner";
import {TravelEcoFr} from "./fr/TravelEco";
import {TravelMediumFr} from "./fr/TravelMedium";
import {TravelExpensiveFr} from "./fr/TravelExpensive";
import {Arrow} from "./fr/Arrow";
import {Bed} from "./fr/Bed";
import {LocationFr} from "./fr/Location";
import {SearchFr} from "./fr/Search";
import {Trash} from "./fr/Trash";
import {Burger} from "./fr/Burger";
import {Copy} from "./Copy";
import { Settings } from './Settings';
import { Message } from './Message';
import { Itinerary } from './fr/Itinerary';
import {ChevronDown} from './ChevronDown';
import {Calendar as FranceCalendar} from './fr/Calendar';
import {Place as FrancePlace} from './fr/Place';
import {RotateRight} from './RotateRight';
import {Pen} from "./Pen";
import { ArmorATable } from './armor/Atable';
import { ArmorAlimentaire } from './armor/Alimentaire';
import { ArmorProducteur } from './armor/Producteur';
import { ArmorLoisirs } from './armor/Loisirs';
import { Fav } from './Fav';
import { Telecharger } from './Telecharger';
import { TravelEcoMartinique } from './TravelEcoMartinique';
import { TravelMediumMartinique } from './TravelMediumMartinique';
import { TravelExpensiveMartinique } from './TravelExpensiveMartinique';

export const pictos = {
  'a-table': ATable,
  'address': Address,
  'advantage-promo': AdvantagePromo,
  'alimentaire': Alimentaire,
  'apple': Apple,
  'arrow-up': ArrowUp,
  'arrow-down': ArrowDown,
  'arrow-left': ArrowLeft,
  'arrow-right': ArrowRight,
  'beaute-sante': BeauteSante,
  'bike': Bike,
  'bin': Bin,
  'burger-menu': BurgerMenu,
  'burger-menu-2': BurgerMenu2,
  'calendar': Calendar,
  'car': Car,
  'caret-down': CaretDown,
  'caret-left': CaretLeft,
  'caret-right': CaretRight,
  'caret-up': CaretUp,
  'caret-right-filled': CaretRightFilled,
  'chat-icon': ChatIcon,
  'check': Check,
  'circle-arrow-right': CircleArrowRight,
  'circle-check': CircleCheck,
  'circle-cross': CircleCross,
  'circle-i': CircleI,
  'circle-percent': CirclePercent,
  'circle-play': CirclePlay,
  'city': City,
  'clip': Clip,
  'clock': Clock,
  'commerce': Commerce,
  'copy': Copy,
  'country': Country,
  'cross': Cross,
  'cursors': Cursors,
  'dots': Dots,
  'download': Download,
  'envelope': Envelope,
  'envelopeNewsletter': EnvelopeNewsletter,
  'facebook': Facebook,
  'giftCard': GiftCard,
  'google': Google,
  'guide-icon': GuideIcon,
  'howto-advantage1': HowtoAdvantage1,
  'howto-advantage2': HowtoAdvantage2,
  'howto-advantage3': HowtoAdvantage3,
  'info': Info,
  'instagram': Instagram,
  'like': Like,
  'list': List,
  'loader': Loader,
  'location': Location,
  'logo': Logo,
  'loisirs': Loisirs,
  'maison-jardin': MaisonJardin,
  'message': Message,
  'mode': Mode,
  'monuments-et-musees': Monuments,
  'evenementiel': Periods,
  'navigation': Navigation,
  'ou-sejourner': OuSejourner,
  'padlock-closed': PadlockClosed,
  'padlock-open': PadlockOpen,
  'pedestrian': Pedestrian,
  'phone': Phone,
  'place': Place,
  'printer': Printer,
  'producteurs-et-artisans': Producteurs,
  'quote': Quote,
  'region': Region,
  'search': Search,
  'settings': Settings,
  'star': Star,
  'target': Target,
  'travel-couple': TravelCouple,
  'travel-eco': TravelEco,
  'travel-medium': TravelMedium,
  'travel-expensive': TravelExpensive,
  'travel-eco-new-ui': TravelEcoNewUI,
  'travel-medium-new-ui': TravelMediumNewUI,
  'travel-expensive-new-ui': TravelExpensiveNewUI,
  'travel-eco-new-ui-v2': TravelEcoNewUIV2,
  'travel-medium-new-ui-v2': TravelMediumNewUIV2,
  'travel-expensive-new-ui-v2': TravelExpensiveNewUIV2,
  'travel-family': TravelFamily,
  'travel-friends': TravelFriends,
  'twitter': Twitter,
  'user': User,
  'voyages': Voyages,
  'youtube': Youtube,
  'thumb-white': ThumbWhite,
  'thumb-black': ThumbBlack,
  'plus': Plus,
  'minus': Minus,
  'like-circle': LikeCircle,
  'dislike-circle': DislikeCircle,
  'heart':HeartIcon,
  'switch-left': SwitchLeft,
  'switch-right': SwitchRight,
  'cutlery': Cutlery,
  'share': Share,
  'like-circle-filled': LikeCircleFilled,
  'dislike-circle-filled': DislikeCircleFilled,
  'euro': Euro,
  'bus': Bus,
  'travel-solo-v2': TravelSoloV2,
  'travel-couple-v2': TravelCoupleV2,
  'travel-family-v2': TravelFamilyV2,
  'travel-friends-v2': TravelFriendsV2,
  'flag': Flag,
  'periods': Periods,
  'dates': Dates,
  'ai-mention': AiMention,
  'walker': Walker,
  'back-packer': BackPacker,
  'runner': Runner,
  'travel-eco-fr': TravelEcoFr,
  'travel-medium-fr': TravelMediumFr,
  'travel-expensive-fr': TravelExpensiveFr,
  'bed': Bed,
  'location-fr': LocationFr,
  'search-fr': SearchFr,
  'arrow-fr': Arrow,
  'trash-fr': Trash,
  'burger-fr': Burger,
  'itinerary-fr': Itinerary,
  'france-calendar': FranceCalendar,
  'france-place': FrancePlace,
  'chevron-down': ChevronDown,
  'rotate-right': RotateRight,
  'pen': Pen,
  'a-table-armor': ArmorATable,
  'alimentaire-armor': ArmorAlimentaire,
  'producteurs-et-artisans-armor': ArmorProducteur,
  'loisirs-armor': ArmorLoisirs,
  'fav': Fav,
  'telecharger': Telecharger,
  'travelEco-martinique': TravelEcoMartinique,
  'travelMedium-martinique': TravelMediumMartinique,
  'travelExpensive-martinique': TravelExpensiveMartinique
};

export interface PictoProps {
  width?: string
  height?: string
  text?: string
  fill?: string
}

export interface PictoWrapperProps {
  iconKey: keyof typeof pictos | string
  width?: string
  height?: string
  text?: string
  fill?: string
  className?: string
}

export const Picto = ({iconKey, ...pictoProps}: PictoWrapperProps) => {
  const PictoComponent = pictos[iconKey];
  if (!PictoComponent) return null;

  return <PictoComponent {...pictoProps} />
};