import React from "react";
import OfferSlide from "./OfferSlide";
import Carousel from "./Carousel";
import {RequirementsProps} from "../../tools/context";

export interface StaticCarouselProps {
  label: string
  subLabel: string
  slides: any[]
  requirements: RequirementsProps
}

const StaticCarousel = (props: StaticCarouselProps) => (
    <Carousel {...props}>
      {props.slides?.map((s, index) => (
          <OfferSlide
              {...props}
              {...s}
              key={s._key || `staticCarousel_${index}`} // Ensure a unique key
          />
      ))}
    </Carousel>
)

export default StaticCarousel;