import React, { useEffect, useState } from "react";

import { ConfigurationUtils } from "./tools/Constants";
import Carousel from "./components/Carousel";
import MiniCarousel from "./components/MiniCarousel";
import BannerImage from "./components/BannerImage";
import PartnerList from "./components/PartnerList";
import Newsletter from "./components/Newsletter";
import { Footer } from "./components/Footer";
import { Header } from "./components/Header";
import ListCategory from "./components/ListCategory";
import ResultGrid from "./components/ResultGrid";
import Shop from "./components/Shop";
import { getCookies, getQueryString, replaceQueryString } from "./tools/tools";
import { RequirementsProps } from "./tools/context";
import EditoHTML from "./components/EditoHTML";
import EditoPage from "./components/EditoPage";
import { ImageEngineProvider } from "@imageengine/react";
import Page404 from "./components/404";
import Portrait from "./components/Portrait";
import * as Heros from "./components/Heros";
import Quote from "./components/Quote";
import Detours from "./components/Detours";
import LightSearchGrid from "./components/LightSearchGrid";
import TilesBlock from "./components/TilesBlock";
import { TravelPlaner } from "./components/TravelPlaner";
import TravelPlanerResults from "./components/TravelPlanerResults";
import { LoginPopup } from "./components/LoginPopup";
import { TopPageNotice } from "./components/TopPageNotice";
import { useTracking } from "./tools/analytics";
import i18next from "i18next";
import HTMLVisualContent from "./components/HTMLVisualContent";
import ContentShowcase from "./components/ContentShowcase";
import ImageContent from "./components/ImageContent";
import TravelPlanerHub from "./components/TravelPlanerHub";

const components = {
  bannerImage: BannerImage,
  editoHTML: EditoHTML,
  editoPage: EditoPage,
  heroLanding: Heros.Landing,
  heroPerso: Heros.Perso,
  heroResult: Heros.Result,
  heroSearch: Heros.Search,
  listCategory: ListCategory,
  mediaCarousel: Carousel.Media,
  miniCarousel: MiniCarousel,
  newsletter: Newsletter,
  partnerList: PartnerList,
  portraitCarousel: Carousel.Portrait,
  portraitCarouselRef: Carousel.PortraitRef,
  portraitPage: Portrait,
  resultGrid: ResultGrid,
  resultGridWithCarousel: ResultGrid,
  searchCarousel: Carousel.Search,
  shop: Shop,
  staticCarousel: Carousel.Static,
  quote: Quote,
  detoursPage: Detours,
  lightSearchGrid: LightSearchGrid,
  tilesBlock: TilesBlock,
  travelPlaner: TravelPlaner,
  travelPlanerHub: TravelPlanerHub,
  travelPlanerResults: TravelPlanerResults,
  loginPopup: LoginPopup,
  htmlVisualContent: HTMLVisualContent,
  contentShowcase: ContentShowcase,
  imageContent: ImageContent,
};

export const getPreloadedState = async (
  cmsData: any,
  requirements: RequirementsProps
) => {
  const preload = {};
  const preloaders = {};

  if (!cmsData || !cmsData.content || !cmsData.content.forEach) return preload;

  cmsData.content.forEach((c) => {
    let comp = components[c._type];
    if (comp && comp.preloader) {
      preloaders[c._key] = comp.preloader(c, requirements);
    }
  });

  await Promise.all(Object.values(preloaders)).then((res) => {
    Object.keys(preloaders).forEach((p, i) => {
      preload[p] = res[i];
    });
  });

  return preload;
};

export const getHeaderTags = (props) => {
  const headers = [];

  if (!props || !props.content || !props.content.forEach) return headers;

  props.content.forEach((c) => {
    const comp = components[c._type];
    const state = props.componentStates[c._key];
    if (comp && comp.headers) {
      headers.push(...comp.headers(state));
    }
  });

  return headers;
};

export const loadCMS = async (
  cmsData: any,
  requirements: RequirementsProps
) => {
  if (!cmsData || !cmsData.content || !cmsData.content.forEach) return cmsData;

  const preloaders = {};

  cmsData.content.forEach((c) => {
    let comp = components[c._type];
    if (comp && comp.cmsLoader) {
      preloaders[c._key] = comp.cmsLoader(c, requirements);
    }
  });

  await Promise.all(Object.values(preloaders)).then((res) => {
    Object.keys(preloaders).forEach((p, i) => {
      const index = cmsData.content.findIndex((c) => c._key === p);
      cmsData.content[index] = res[i];
    });
  });

  return cmsData;
};

const formatInternationalizedContent = (content: any, language: string) => {
  if (!!!content) return content;

  if (typeof content === "string") return content;

  if (content._type === "localeString") {
    const keys = Object.keys(content).filter((key) => key !== "_type");
    const key = keys.find((key) => language.startsWith(key)) || "en";
    return content[key] ?? content.fr;
  }

  if (
    Array.isArray(content) &&
    content[0]?._type === "internationalizedArrayStringValue"
  ) {
    const localizedText =
      content.find((text) => language.startsWith(text._key)) ||
      content.find((text) => text._key === "en") ||
      content.find((text) => !!text);
    return localizedText ? localizedText.value : "";
  }

  if (Array.isArray(content)) {
    return content.map((c) => formatInternationalizedContent(c, language));
  }

  if (typeof content === "object") {
    const result = {};

    for (const key in content) {
      if (content.hasOwnProperty(key)) {
        result[key] = formatInternationalizedContent(content[key], language);
      }
    }

    return result;
  }

  return content;
};

export interface OverLayParameter {
  key: "loginPopup";
  context?: any;
}

const App = (baseProps) => {
  const props = formatInternationalizedContent(baseProps, i18next.language);

  const [overlay, setOverlay] = useState<OverLayParameter>();
  const { initTracking, trackEvent } = useTracking();

  useEffect(() => {
    initTracking();
    const cookies = getCookies();
    if (cookies.loginToken) {
      if (getQueryString("successLoginPhone")) {
        trackEvent("success_login_phone");
        replaceQueryString("successLoginPhone", null);
      }
      if (getQueryString("successLoginGoogle")) {
        trackEvent("success_login_google");
        replaceQueryString("successLoginGoogle", null);
      }
    }

    const root = document.querySelector(":root") as HTMLDivElement;
    if (typeof props.siteColor !== "undefined") Object.keys(props.siteColor).forEach((sc) =>
      root.style.setProperty(`--${sc}`, props.siteColor[sc])
    );
    if (typeof props.siteFont !== "undefined") Object.keys(props.siteFont).forEach((sc) =>
      root.style.setProperty(`--${sc}`, props.siteFont[sc])
    );
  }, []);

  if (!props?.content?.map) {
    return (
      <Page404
        requirements={props.requirements}
        navigation={props.navigation}
        headerButtons={props.headerButtons}
      />
    );
  }

  if (props?.configuration) {
    ConfigurationUtils.setContext(props.configuration);
  }

  const handleGetComponent = (c) => {
    const comp = components[c._type];
    if (!comp)
      return <div key={c._key}>Error no component render : {c._type}</div>;

    const Comp = comp.Renderer || comp;

    return (
      <Comp
        {...c}
        key={c._key}
        sellerState={props.sellerState}
        requirements={props.requirements}
        componentStates={props.componentStates[c._key]}
        navigation={props.navigation}
        headerButtons={props.headerButtons}
        setOverlay={setOverlay}
        filtersConfiguration={props.filtersConfiguration}
        siteConfiguration={props.siteConfiguration}
      />
    );
  };

  const handleOverlay = () => {
    if (!overlay || !overlay.key) return null;

    const comp = components[overlay.key + ""];
    const Comp = comp.Renderer || comp;
    return (
      <Comp setOverlay={setOverlay} context={overlay.context} {...props} />
    );
  };

  return (
    <ImageEngineProvider deliveryAddress="https://tourismebyca.twic.pics/">
      <TopPageNotice
        notice={props.topPageNotice}
        requirements={props.requirements}
      />
      <div className="App">
        {!props.header?.hidden && (
          <Header
            navigation={props.navigation}
            requirements={props.requirements}
            headerButtons={props.headerButtons}
          />
        )}
        {props.hero && props.hero.map(handleGetComponent)}
        {props.content.map(handleGetComponent)}
        {handleOverlay()}
        {!props.footer?.hidden && (
          <Footer requirements={props.requirements} footer={props.footer} />
        )}
      </div>
    </ImageEngineProvider>
  );
};

export default App;
