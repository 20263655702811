import React, { useEffect, useRef, useState } from "react";
import { Picto } from "../Picto";

export interface CarouselProps {
  label: string;
  subLabel?: string;
  slides: any[];
  children: any;
}

const Carousel = (props: CarouselProps) => {
  const { label, subLabel, slides, children } = props;
  const scrollRef = useRef<HTMLDivElement>();
  const [showPrev, setShowPrev] = useState<boolean>(false);
  const [showNext, setShowNext] = useState<boolean>(true);

  useEffect(() => {
    setShowPrev(!!scrollRef.current.scrollLeft);
    setShowNext(
      scrollRef.current.scrollLeft + scrollRef.current.clientWidth <
        scrollRef.current.scrollWidth
    );
  }, []);

  const handleNextPrev = (prev?: boolean) => {
    const scrollWidth = scrollRef.current.scrollWidth;
    const clientWidth = scrollRef.current.clientWidth;
    const tick = scrollWidth / slides.length;
    const page = Math.floor(clientWidth / tick);

    scrollRef.current.scrollBy({
      left: (prev ? -1 : 1) * tick * page,
      behavior: "smooth",
    });
  };

  const handleScroll = (e) => {
    const ref = e.target;
    setShowPrev(!!ref.scrollLeft);
    setShowNext(ref.scrollLeft + ref.clientWidth < ref.scrollWidth);
  };

  return (
    <div className="carousel">
      <div className="header">
        {label}
        <span>{subLabel || ""}</span>
      </div>
      <div
        className="scrollBloc"
        nonce="__nonce__"
        style={{ position: "relative" }}
      >
        <div
          ref={(ref) => (scrollRef.current = ref)}
          onScroll={handleScroll}
          className="scrollpart"
        >
          {children}
        </div>
        {showPrev && (
          <button
            onClick={() => handleNextPrev(true)}
            className="pageButton previous"
          >
            <Picto iconKey={"arrow-left"} />
          </button>
        )}
        {showNext && (
          <button onClick={() => handleNextPrev()} className="pageButton next">
            <Picto iconKey={"arrow-right"} />
          </button>
        )}
      </div>
    </div>
  );
};

export default Carousel;
