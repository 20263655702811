import { Loader } from "@googlemaps/js-api-loader";
import {
  defaultOnClusterClickHandler,
  MarkerClusterer,
} from "@googlemaps/markerclusterer";
import { OverlappingMarkerSpiderfier } from "ts-overlapping-marker-spiderfier";
import * as Configurations from "../configurations";
import { getPreloadedState, loadCMS } from "../App";
import { defReq, RequirementsProps } from "./context";
import { Endpoints } from "./Constants";
import { getSanityClient } from "./sanity";

type LatLngLiteral = google.maps.LatLngLiteral;
declare const window: any;

const fetchArticlesData = async (cityKey, apiKey, secretKey) => {
  const token =`Bearer ${secretKey}`
  const url = `https://clients.wearegenial.com/api/v1/france/articles`;
  const params = new URLSearchParams();
  params.append('city_key', cityKey);  // Use variable cityKey instead of hardcoding

  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Authorization': token,
        'Content-Type': 'application/x-www-form-urlencoded',
        'api-key': apiKey,
      },
      body: params,
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Failed to fetch data:", error);
  }
};


const preloadState = async (
  url: string,
  query: any,
  host: string,
  cookies: { [key: string]: any },
  useragent: string
) => {
  const parsed = new URL(url, "https://test.com");

  let requirements: RequirementsProps = getUrlInfoRequirements(
    url,
    parsed.pathname,
    query,
    host,
    cookies,
    useragent
  );
  let cmsState = await getCMSData(requirements);
  cmsState = await loadCMS(cmsState, requirements);
  let componentStates = await getPreloadedState(cmsState, requirements);

  return { ...cmsState, componentStates: componentStates, requirements };
};

const getPartner = (
  host: string,
  cookies: { [key: string]: any },
  queries: any
) => {
  let partner = cookies.partner;

  const partnerFromUrl = [
    ".ticket-pei.fr",
    ".ticket-commercant.fr",
    ".reducpeyi.fr",
    ".bordeaux-mon-commerce.fr",
  ];
  let bvnDomain = partnerFromUrl.some((url) => host.endsWith(url))
    ? host.split(".")[0]
    : null;
  bvnDomain = bvnDomain !== "dev" && bvnDomain !== "fork" ? bvnDomain : null;

  if (queries) {
    partner =
        queries.part ||
        queries.partner ||
        queries.sponsor ||
        bvnDomain ||
        partner ||
        "tca";
  } else {
    partner = bvnDomain || partner || "tca";
  }

  if (partner === "prod") partner = "tca";

  const partnerDomains = {
    "myprovence.fr": "passprovence",
    "passmyprovence.fr": "passprovence",
    "tourismebyca.fr": "tca",
    "bienvenus.fr": "bvn",
    "lavalagglo.bienvenus.com": "lavalagglo",
    "loreedelabrie.bienvenus.com": "loreedelabrie",
    "tickets-intermarche.fr": "intermarche",
    "bordeaux-mon-commerce.fr": "bordeauxmoncommerce",
    "avantageariege.fr": "ariege",
    "avantagesariege.fr": "ariege",
    "passevasionsiblu.fr": "siblu",
    "martinique.org": "martinique",
    "reducpeyi.fr": "otc",
    "terresducentremartinique.fr": "otc",
    "zanmi.ai": "otc",
    "marianne.france.fr": "france",
    "tourismecorreze.com": "correze",
    "avantagesiledefrance.fr": "iledefrance",
    "tourisme-orleansmetropole.com": "orleans",
    "avantagearmor.fr": "armor",
    "savoirfairedesalpilles.fr": "ccvba"
  };
  const domPartKey = Object.keys(partnerDomains).find((k) => host.endsWith(k));
  partner = domPartKey ? partnerDomains[domPartKey] : partner;

  return partner;
};

const getUrlInfoRequirements = (
  href: string,
  path: string,
  query: any,
  host,
  cookies: { [key: string]: any },
  useragent: string
) => {
  let req: RequirementsProps = {
    ...defReq,
    href,
    host,
  };

  if (query) {
    req = {
      ...req,
      ...query,
    };

    req.aroundme = query.aroundme === "true";
    req.mapOpened = query.mapOpened === "true";
    req.sellerId = query.seller;
    req.shortcode = query.shortcode;
    req.campaign = query.campaign;
    req.location = query.location && decodeURIComponent(query.location);
    req.department = query.department && decodeURIComponent(query.department);
    req.departement =
      query.departement && decodeURIComponent(query.departement);
    req.query = query.query && decodeURIComponent(query.query);
    req.useragent = useragent;
    ["categ", "labels", "filters"].forEach((k) => {
      req[k] = query[k] && decodeURIComponent(query[k]).split("|");
    });
  }

  let res = path;

  req.partner = getPartner(host, cookies, query);
  req.config = Configurations[req.partner];

  if (
    host.startsWith("localhost") ||
    host.startsWith("qa.") ||
    host.startsWith("ssr-dev.") ||
    host.startsWith("dev.")
  ) {
    if (req.config?.dev) {
      req.config = { ...req.config, ...req.config.dev };
    }

    req.environment = "qa";
  }

  if (query?.endpoint || cookies.endpoint) {
    const ep = query.endpoint || cookies.endpoint;
    if (Object.keys(Endpoints).includes(ep)) {
      req.environment = ep;
    }
  }

  req.endpoint = Endpoints[req.environment];

  if (res === "/") return req;

  if (res[0] === "/" && res.length > 1) {
    res = res.substring(1);
  }

  const split = res.split("/");

  req.path = `/${split[0]}`;
  req.parameters = split.slice(1);

  return req;
};

const replaceRef = async (input, client) => {
  const content = [...input];
  const references = content.filter((c) => c._ref);

  if (!references || !references.length) {
    return input;
  }

  const refQuery = references.map((r) => `_id == "${r._ref}"`).join(" || ");
  const refData = await client.fetch(`*[${refQuery}]`);

  if (!refData || !refData.forEach) {
    return input;
  }

  refData.forEach((r) => {
    const index = content.findIndex((c) => c._ref === r._id);
    if (index < 0) return;
    content[index] = { ...content[index], ...r };
  });

  return content;
};

const findInCMSData = (obj: any, keyToFind: string): Array<string> => {
    let results = [];
    function recurse(obj: any) {
      if (obj !== null && typeof obj === "object") {
        if (keyToFind in obj) {
          results.push(obj[keyToFind]);
        }
        Object.values(obj).forEach(value => {
          if (typeof value === "object") {
            recurse(value);
          }
        });
      }
    }
    recurse(obj);
    return results;
  }

const getCMSData = async (requirements: any) => {
  let client = getSanityClient(requirements);

  const query = `*[(_type == "page" && path.current == "${requirements.path}") || _type == "configuration"]`;
  const data = await client.fetch(query);

  if (!data || !data.length) return {};

  let configuration = null;
  let otherConfigs = {};
  const config =
    data.find(
      (d) => d._type === "configuration" && d._id.startsWith("drafts.")
    ) || data.find((d) => d._type === "configuration");

  if (config) {
    otherConfigs = {
      ...config,
      siteConfiguration: config.siteConfiguration || {},
    };
    try {
      configuration = JSON.parse(config.configuration);
    } catch (e) {}
  }

  let output: any = {
    ...otherConfigs,
    configuration
  };

  const content = data.filter((d) => d._type === "page");
  if (!content.length) return output;

  const preview =
    content.find((d) => d._id.startsWith("drafts.")) || content[0];
  output = {
    path: preview.path.current,
    ...preview,
    ...output,
    topPageNotice: {
      ...(output.topPageNotice || {}),
      ...(preview.topPageNotice || {}),
    },
    headerTags: [
      ...(output.headerTags || []),
      ...(preview.headerTags || content.headerTags || []),
    ],
  };

  if (output.hero && output.hero.length) {
    output.hero = await replaceRef(output.hero, client);
  }
  if (output.content && output.content.length) {
    output.content = await replaceRef(output.content, client);
  }

  return output;
};

const createSitemap = async (requirements: RequirementsProps) => {
  let client = getSanityClient(requirements);

  const queryUrls =
    '*[_type == "page"]{"lastUpdate": _updatedAt, "path": path.current, name}';
  console.log("cms query:" + queryUrls);
  const urls = await client.fetch(queryUrls);

  const querySlugs =
    '*[_type == "detours" || _type == "portrait"]{"type": _type, "slug": slug.current}';
  console.log("cms query:" + querySlugs);
  const slugs = await client.fetch(querySlugs);

  return `<?xml version="1.0" encoding="UTF-8"?>
  <urlset xmlns="http://www.sitemaps.org/schemas/sitemap/0.9">
    ${urls
      .filter(
        (f) =>
          !f.name.toLowerCase().includes("test") &&
          f.path !== "/portrait" &&
          f.path !== "/detours"
      )
      .map(
        (u) => `
    <url>
      <loc>https://tourismebyca.fr${u.path}</loc>
      <lastmod>${u.lastUpdate}</lastmod>
    </url>`
      )
      .join("")}
    ${slugs
      .filter((f) => !f.slug || !f.slug.toLowerCase().includes("test"))
      .map((s) => {
        let path = "";
        switch (s.type) {
          case "detours":
            path = "/detours";
            break;
          case "portrait":
            path = "/portrait";
            break;
          default:
            return "";
        }

        return `
      <url>
        <loc>https://tourismebyca.fr${path}/${s.slug}</loc>
      </url>`;
      })
      .join("")}
  </urlset>
`;
};

export interface MarkersProps {
  geoloc: any;
  category: string;
  content?: string;
}

const loadMap = (
  divRef: HTMLDivElement,
  center: any,
  markers: MarkersProps[],
  zoom?: number,
  position?: LatLngLiteral
) => {
  if (typeof window !== "undefined" && window.google) {
    initiateMap(divRef, center, markers, zoom, position);
    return;
  }

  const loader = new Loader({
    apiKey: "AIzaSyCYp4mEcSL6CN2IjfVnVjnJDdWCWeGhUz8",
    version: "weekly",
    libraries: ["places"],
  });

  loader.load().then(() => {
    initiateMap(divRef, center, markers, zoom, position);
  });
};

const initiateMap = (
  divRef: HTMLDivElement,
  center: any,
  marks: MarkersProps[],
  zoom?: number,
  position?: LatLngLiteral
) => {
  let mapExist = !!window.map;

  if (mapExist && window.map.getDiv() !== divRef) {
    mapExist = false;
  }

  if (!mapExist) {
    if (google.maps?.Map === undefined) return;
    window.map = new google.maps.Map(divRef, {
      zoom: zoom || 15,
      center,
      styles: [{ featureType: "poi", stylers: [{ visibility: "off" }] }],
    });
    const options = { legWeight: 3 }; // Just an example of options - please set your own if necessary
    window.oms = new OverlappingMarkerSpiderfier(window.map, options);
    window.clusterer = new MarkerClusterer({
      map: window.map,
      markers: [],
      onClusterClick: (e, c, m) => {
        defaultOnClusterClickHandler(e, c, m);
      },
    });
  }
  const map = window.map;
  const oms = window.oms;
  let markers = window.markers || [];
  const clusterer = window.clusterer;

  if (mapExist) {
    markers.forEach((m) => {
      m.setMap(null);
      oms.removeMarker(m, null);
    });
    clusterer.clearMarkers();
  }
  if (!marks || !marks.length) return;

  const infoWindow = new google.maps.InfoWindow({
    content: "",
    disableAutoPan: false,
  });

  let latlong = new google.maps.LatLngBounds();
  markers = marks.map((m) => {
    if (!m.geoloc) return;

    const marker = new google.maps.Marker({
      position: m.geoloc,
      map,
      icon: `https://tourismebyca.twic.pics/static/tca/v2/pin/${m.category}.png`,
    });
    let c = new google.maps.LatLng(m.geoloc);
    if (!zoom) latlong.extend(c);
    if (m.content) {
      // marker.addListener("click", () => {
      //   infoWindow.setContent(m.content);
      //   infoWindow.open(map, marker);
      // });
      google.maps.event.addListener(marker, "spider_click", function (e) {
        // 'spider_click', not plain 'click'
        infoWindow.setContent(m.content);
        infoWindow.open(map, marker);
      });
      oms.addMarker(marker, null);
    }

    return marker;
  });

  google.maps.event.addListener(map, "drag", () => {
    infoWindow.close();
  });
  google.maps.event.addListener(map, "zoom_changed", () => {
    infoWindow.close();
  });
  // Path for cluster icons to be appended (1.png, 2.png, etc.)
  // const imagePath = "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m";

  clusterer.addMarkers(markers);

  if (position) {
    const icon = {
      url: `https://tourismebyca.twic.pics/static/tca/v2/pin/geoloc.png`, // url
      scaledSize: new google.maps.Size(30, 30), // scaled size
      origin: new google.maps.Point(0, 0), // origin
      anchor: new google.maps.Point(0, 0), // anchor
    };

    new google.maps.Marker({
      position,
      map,
      icon,
    });
    if (!zoom) latlong.extend(position);
  }
  if (!zoom) map.fitBounds(latlong);
};




export {
  getCMSData,
  preloadState,
  loadMap,
  createSitemap,
  getUrlInfoRequirements,
  fetchArticlesData,
};
